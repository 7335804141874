@import url('https://fonts.googleapis.com/css2?family=Bona+Nova&family=Oxygen:wght@300&family=Roboto&display=swap');



:root{
  --bg-color: #2B7A0B;
  --second-bg-color: #5BB318;
  --text-color: #F1F6F9;
  --third-bg-color: #7DCE13;
  /* --yellow-bg-color: #EAE509; */
  --yellow-bg-color: #dbc51c;
}
.btn, .viewmore{
  margin: 20px;
  margin-left: 40px;
  padding: 10px 20px;
  border: none;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  /* background-color: var(--yellow-bg-color); */
}
.backbtn{
  margin: 20px;
  margin-left: 40px;
  padding: 10px 20px;
  border: none;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: inherit;
}
.moreBtn{
display: flex;
justify-content: center;
align-items: center;
}
.NewmoreBtn{
  text-align: center;
  width: 55%;
  margin: auto;
}
.btnicon{
  width: 30px;
  height: 30px;
  object-fit: contain;
}
.radio{
  width: 100%;
  height: 400px;
}
.time{
  text-transform: lowercase;
}

.clock {
  margin: 0 auto;
  margin-right: 30px;
}
.clock h3{
  font-family: 'Bona Nova', serif;
}

.timer{
  margin-right: 30px;
}
.radioShedule{
  display: flex;
  align-items: center;
  width: 95%;
  margin: auto;
}
.radioShedule h3, .radioShedule p{
  margin-right: 5px;
  font-weight: 900;
}
.hidder{
  width: 100%;
  background-color: var(--bg-color);
  padding: 6px 120px;
  position: absolute;
  top: 110px;
  z-index: 10000;
  display: flex;
  align-items: center;
}
.mobileHider, .mobileHiderHolder{
  display: none;
}
.listner{
  display: flex;
  align-items: center;
}
.listenIcon{
  width: 50px;
  height: 50px;
}
.watcher{
  position: relative;
  font-family: 'Roboto', serif;
}
.liveHolder{
  position: absolute;
  left: 95px;
  top: 20px;
}
.livetv{
  width: 50px;
  height: 50px;
  position: relative;
}
.circular{
  width: 22px;
  height: 22px;
  position: absolute;
  top: -29px;
  left: 4px;
}
.watchBanner{
  background-color: var(--bg-color);
  color: black;
  padding: 8px 143px;
  font-weight: bold;
  border-bottom: 1px solid darkgray;
}
.watch{
  background-color: var(--bg-color);
}

.App {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* background: black; */
  color: black;
  font-family: 'Bona Nova', serif;
  font-size: '18px';
}

/* NAVIGATION SECTION  */
.nav a{
  text-decoration: none;
  color: black;
}
.nav{
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: white;
  width: 200px;
  border-bottom: 1px solid gray;
}
.nav_brand{
  text-transform: uppercase;
  font-weight: bold;
  display: flex;
  align-items: center;
  margin-top: -5px;
}
.nav_brand img {
    width:'100px';
    height:'100px';
   padding:'10px';
   padding-top: 0px;
  }
.nav_menu{
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 2rem 3rem;
  padding-top: 50px;
}
.nav_menu li{
  list-style: none;
}
.nav_toggler div{
  width: 1.5rem;
  height: 0.2rem;
  margin: 0.1rem;
  background-color: darkgray;
}
.nav_toggler{
  cursor: pointer;
  display: none;
}
.nav_men{
  display: none;
}
.nav_link{
  font-weight: bold;
}


.whiteText{
  color: black;
}
.myFonts{
  color: var(--second-bg-color);
}

/* const designs */

.myFlex{
  display: flex;
  align-items: center;
}
.myList{
  list-style: none;
}
.myLink{
  text-decoration: none;
  color: white;
}
.newLink{
  text-decoration: none;
}
.myCircle, .homecardImage{
  border-radius: 50%;
}
        /* privacy nav  */

.privacyMedia{
  color: white;
}

.privacy li{
  list-style: none;
  padding: 5px;
}

.media{
  display: flex;
  align-items: center;
}
.media-icons{
  display: flex;
  align-items: center;
}
.media-icons li{
  list-style: none;
  margin-right: 20px;
}
.webNav{
  text-decoration: none;
  color: black;
}
.icons{
  color: var(--yellow-bg-color);
}

      /* Main Navigation */

.main-nav{
  display: flex;
  justify-content: space-between;
  background-color: var(--yellow-bg-color);
  color: white;
  padding: 10px 40px;
  border-bottom: 1px solid lightgray;

}
.logoSec{
  display: flex;
  align-items: center;
}
.logo{
  height: 50px;
  width: 60px;
  margin-right: 5px;
  background-color: white;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.logoText{
  color: red;
  font-size: 15px;
  font-weight: bold;
}

.webNav{
  display: flex;
  align-items: center;
    font-size: 15px;
  font-weight: bold;
}
.webNav li{
  list-style: none;
  margin-right: 25px;
}
.liveOnAir{
  display: flex;
  align-items: center;
}
.liveonAirImage{
  background-color: white;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  margin-right: 10px;
}
.liveonairTittle li{
  list-style: none;
  font-size: 15px;
  font-weight: bold;
}

/* banner section */

.bannerPic{
  height: 500px;
  width: 100%;
  background-color: rgb(58, 55, 55);
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.homeBannerEvents{
  background-color: rgba(0, 0, 0, 0.596);
  color: black;
  padding: 8px 143px;
  font-weight: bold;
  border-bottom: 1px solid darkgray;

}
.homeBannerSection1{
  margin-right: 40px;
  display: flex;
  align-items: center;
  font-weight: bold;
}
.homeBannerSection1 span{
  color: red;
  margin-right: 5px;
}
.homeBannerSection2{
  display: flex;
  align-items: center;
  column-gap: 60px;
}
.homecard1{
  display: flex;
  align-items: center;
  margin-right: 50px;
}
.homeBannerSection2 li{
  list-style: none;
  padding-top: 10px;
}
.homecardImage{
  width: 100px;
  height: 100px;
  background-color: white;
  margin-right: 10px;
}
.homecardDay{
  font-size: 12px;
}

/* news section  */
.viewmore{
  background-color: inherit;
}
.btnName{
  color: black;
}
.gencontainer{
  padding: 20px 145px;
  border-bottom: 1px solid darkgray;
}
.gridcontainer,.showsSlider,.photogallary{
  margin: auto;
}
.newsHeader{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.newsGrid, .sliderCards{
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
  padding-bottom: 50px;
}
.sliderCard{
  width: 250px;
  margin: 10px 0 0 0;
}
.newsCard{
  height: 200px;
  width: 200px;
  margin: 10px 0 0 20px;
}
.newsCardImage, .sliderCardImg{
  height: 200px;
  border-radius: 10px;
}


/* Television Section  */
.television{
  padding: 0px 140px;
}
.frameImage{
  height: 400px;
  width: 100%;
  background-color: white;
}
.tvGrid{
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
  width: 100%;
  margin-top: 40px;

}
.textLimit{
  display: -webkit-box;
    -webkit-line-clamp: 2; /* Change the number to the desired line limit */
    -webkit-box-orient: vertical;
    overflow: hidden;

}


/* Shows Section  */

.showsContainer{
  padding: 20px 143px;
  border-bottom: 1px solid darkgray;
}
.showsContainer-mobile{
  display: none;
}

.sliderCards{
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  padding: 0px 0px;
  margin: 0, auto;
}
.sliderCard li{
  list-style: none;
}

/* Presenter-section */

.presenterPage-container{
  display: block;
}

.page-container-mobile{
  display: none;
}
.Presenter-header, .presenterHeader{
  padding-bottom: 20px;
}

.presenterContainer{
  padding: 20px 143px;
  border-bottom: 1px solid darkgray;
  color: black;
  
}
.presenterProfile{
  padding: 0;
  width: 100%;
  overflow-x: scroll;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  animation: slideInRight 3s forwards;
  

}
.presenterProfileHolder{
  overflow-x: scroll;
}
.presenterCard{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;
}

.v-more-btn{
  color: white;
}

/* presenter  */
.dtailsBanner{
  width: 35%;
  margin-bottom: 40px;
}


.contact-container{
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 50px;
}
.contact{
  width: 30%;
  margin: auto;
  background-color: white;
  padding: 20px 40px 50px;
  color: black;
}
/* footer section */

.footerContainer{
  padding: 20px 120px;
  color: white;
  display: flex;
  justify-content: space-between;
  gap: 20px;
  border-top: 1px solid slategray;
}



.otherStation li{
  list-style: none;
  padding: 5px;
  text-align: left;
}

.connectWithUs li{
  list-style: none;
  padding: 5px;
}

.siteMap li{
  list-style: none;
  padding: 5px;
}
.socialMedia, .siteApp{
  display: flex;
  align-items: start;
}

.socialMedia li{
  list-style: none;
  margin-right: 10px;
}

.siteApp{
  list-style: none;
  margin-right: 10px;
}
.copyRight{
  background-color: white;
  color: black;
  padding: 5px 120px;
  display: flex;
  justify-content: space-between;
  border-top: 1px solid slategray;
  font-weight: lighter;
}


.fInput{
  width: 90%;
  height: 30px;
  border: 1px solid black;
  border-radius: 5px;
  padding: 5px 10px;
  margin: auto;
}
.formInput{
  outline: none;
  border-radius: 5px;
  height: 100%;
  width: 100%;
  outline: none;
  border: none;
  font-size: 18px;
}
.formbtn{
  text-align: center;
  margin: 30px 0px;
}
.formbtn button{
  padding: 10px 20px;
  border: none;
  background-color: var(--yellow-bg-color);
  color: var(--bg-color);
  border-radius:5px;
  font-size: 15px;
}
.formbtn button:hover{
  background-color: var(--bg-color);
  color: var(--yellow-bg-color);
  padding: 12px 20px;
}

.notFound{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}
.warning{
  display: flex;
  align-items: center;
  color: red;
}
.modal-active{
  display: block;
}
.modal{
  width: 100%;
  height: 100vh;
  position: absolute;
  z-index: 1000;
  top: 0;
  background-color: rgba(0, 0, 0, 0.59);
  display: block;
}

.headersFont{
  font-size: 23px;
}
/* ABOUT */
.about-container{
  text-align: justify;
  padding: 10px 130px;
}
.photogallary{
  margin: 40px 0px;
}
.photoImage{
  width: 300px;
  height: 250px;
  border: 1px solid lightgrey;
  padding: 10px;
}
.photoGrid{
  display: flex;
  overflow-x: scroll;
  gap: 30px;
}

/* Responsiveness */
@media(max-width:900px){
  .main-nav {
    display: flex;
    padding: 20px 10px;
  }
  .media-icons, .liveOnAir{
    display: none;
  }
  .logoSec {
    display: block;
  }
  .bannerPic{
  height: 300px;
  background-color: rgb(58, 55, 55);
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.newsGrid{
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
  padding-bottom: 50px;
}
.showsContainer{
  padding: 20px 143px;
  border-bottom: 1px solid darkgray;
  background-color: var(--bg-color);
}
.sliderCards{
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  padding-bottom: 50px;
}
.homeBanner{
  padding: 0;
  margin: 0;
  background-color: black;

}
.homeBannerEvents{
  display: flex;
  color: white;
  padding: 20px 10px 0px;
  align-items: center;

}
.homeBannerSection1{
  margin-right: 0px;
  display: block;
  align-items: center;
  padding: 5px 10px;
  border-radius: 5px;
  background-color: var(--bg-color);
}
.homeBannerSection2{
  display: none;
  align-items: center;
  gap: 60px;
}
.homecard1{
  display: flex;
  gap: 10px;
  text-align: center;
  align-items: center;
  margin-right: 0;
  margin-bottom: 50px;
}
.homecardImage{
  width: 50px;
  height: 50px;
}
/* News section  */
.newsGrid{
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  padding: 0px 0px;
  column-gap: 30px;
  width: 80%;
  margin: 0, auto;
}
.newsCard{
  height: 250px;
  width: 200px;
  margin: 20px 0 0 0;
}



}
@media(max-width:768px) {
  .nav_toggler{
    display: block;
  }
  .nav{
    position: sticky;
    background-color: white;
    top: 0;
    z-index: 1;
    height: 100px;
    border-bottom: 1px solid lightgrey;
  }
  .nav_menu{
    position: fixed;
    top: 39px;
    right: 0;
    width: 50%;
    background-color: red;
    flex-direction: column;
    gap: 1rem;
    transform: translateX(100%);

    top:'100px'
  }
  .nav_men{
    background-color: rgba(0, 0, 0, 0.836);
    position: absolute;
    z-index: 10000000;
        transform: translateX(-100%);
    transition: 0.5s ease-in;
  }
  .nav_men li{
    list-style: none;
    padding: 10px 80px;

  }
  .nav_men li a{
    text-decoration: none;
    color: white;
  }
  /* Nav active class  */
  .nav_active{
    transform: translateX(0%);
    transition: 0.5s ease-out;
    display: block;
  }

  /* Toggler icon animation  */
  .toggle .line1{
    transform: rotate(-47deg) translate(-4px, 5px);
  }
  .toggle .line2{
    opacity: 0;
  }
  .toggle .line3{
    transform: rotate(45deg) translate(-4px, -5px);
  }
  .newsGrid{
    grid-template-columns: repeat(3, 1fr);
  }
}
@media(max-width:470px){
  .nav{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width:100%;
  padding: 5px 0px;
  background-color: white;
}
.nav_toggler{
  padding: 30px 30px 0px;
}
.nav_men{
  list-style: none;
  padding-bottom: 30px;
}
.nav_link{
  text-decoration: none;
  color: white;
}
.nav_link:hover{
  background-color: white;
  color: var(--bg-color);
}
.nav_link:hover .nav_link{
  color: var(--bg-color);
}

.nav_brand{
  text-transform: uppercase;
  font-weight: bold;
  display: flex;
  align-items: end;
  padding: 0px;
}
.logostyle {
    width:'150px';
    height:'100px';
   padding:'7px';
   padding-top: 0px;
  }
.hidder{
  background-color: var(--bg-color);
  padding: 0px 22px;
  position: absolute;
  top: 110px;
  z-index: 10000;
  display: none;
  align-items: center;
}

.timer{
  padding: 0;
}

.radioShedule{
  width: 100%;
  display: block;
}
.radioevents{
  display: flex;
  justify-content: space-between;
  background-color: red;

}
.mobileHider{
  width: 100%;
  background-color: var(--bg-color);
  position: absolute;
  top: 110px;
  left: 0;
  z-index: 1000;
  display: block;
  overflow-x: hidden;
}
.mobileHiderHolder{
  width: 87%;
  margin: auto;
  display: flex;
}
.mobileHiderHolder h3{
  margin-right: 70px;
}
.radioEVT{
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.radioEVT h4{
  margin-right: 10px;
}
.watchBanner{
  padding: 0px 20px;
  background-color: none;
}
  .privacyMedia, .privacy ul, .main-nav {
    display: block;
    padding: 1% 5px;
  }
  .privacy ul li{
    padding: 10px 0px;
  }
  .media-icons, .liveOnAir{
    display: none;
  }
  .logoSec, .webNav {
    display: block;
  }
  .logoSec, .webNav{
    padding: 10px 20px;
  }
  .bannerPic{
  height: 200px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.homeBanner{
  padding: 0;
  margin: 0;
}
.homeBannerEvents{
  display: flex;
  color: white;
  padding: 10px 10px ;
  align-items: center;
}
.homeBannerSection1{
  margin: 0;
  align-items: center;
  padding: 5px 10px;
  border-radius: 5px;
}
.homeBannerSection1 Link span{
  font-size: 10px;
  color: blue;
}

.homeBannerSection2{
  display: none;
  align-items: center;
  gap: 60px;
}
.homecard1{
  display: flex;
  gap: 10px;
  text-align: center;
  align-items: center;
  margin-right: 0;
  margin-bottom: 50px;
}
.homecardImage{
  width: 50px;
  height: 50px;
}
.homebannerLiveSec{
  width: 100%;
  height: 300px;
}

.gencontainer{
  padding: 5px 20px 30px;
  border-bottom: 1px solid darkgray;
  color: white;
  /* background-color: var(--bg-color); */
}
.gridcontainer{
  width: 90%;
  margin: auto;
}

.newsGrid{
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
}
.newsHeader{
  display: block;
  justify-content: space-between;
  align-items: center;
}
.newsArrow{
  display: none;
}
.gridcontainer{
  margin: 0px auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
.newsGrid{
  display: flex;
  overflow-x: scroll;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: auto;
  column-gap: 30px;
}
.newsGrid-shows{
  display: block;
  grid-template-columns: repeat(2, 1fr);
  padding: 20px 20px;
  gap: 30px;
  width: 100%;
  margin: 0, auto;
}
.newsCard{
  height: 300px;
  width: 200px;
}
.newsCardImage{
  height: 200px;
  border-radius: 5px;
}
.newsCardHeader h3{
  margin: 5px 0px;
  margin-top: 20px;
}


/* tv  */
.presenterContainer{
  display: block;
  padding: 5px 20px 10px;

}
.mb-header{
  text-align: left;
}
.presenterPage-container{
  display: none;
}
.page-container-mobile{
  display: block;
  padding: 20px 20px 30px;
}

.presenterProfileHolder{
  display: flex;
  gap: 50px;
  padding: 0px 20px;
  align-items: center;
  overflow-x: scroll;
}
.card-mobile{
  margin-top: 10px;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  width: 300px;
  margin-bottom: 10px;
}
.card_mobile_shows{
  margin-bottom: 20px;
}
.profileInfo{
  padding: 0;
  margin: 0;
}
.profileInfoMobile{
  display: flex;
  flex-direction: column;
}
.newsCardImage_mobile{
  width: 300px;
  margin: auto;
  display: flex;
  padding-bottom: 10px;
}
.newsCardImage_mobileShows{
  display: block;
}

.presenterProfiles{
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 30px;
}
.Profile{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.profileInfomobile{
  width: 30%;
}


/* presenters page*/
.Presenter-header{
  padding-bottom: 50px;
}
.presenterProfileHolder{
  display: flex;
  align-items: center;
  justify-content: center;
}
.presenterProfiles{
  display: flex;
  align-items: center;
}

.presenterProfile{
  overflow-x: scroll;
  display: flex;
  column-gap: 50px;
  margin: auto;
  color: black;
  padding: 20px 20px 0px;
}

/* .profileImage{
  border-radius: 50%;
  width: 150px;
  height: 150px;
  background-color: white;
} */
/* .profileImage{
  text-align: center;
} */
/* .Profile{
  display: block;
  column-gap: 80px;
  width: 80%;
  align-items: center;
  padding: 20px 0px;
} */
/* presenters end  */
.presenterPage-container{
  color: black;
  background-color: white;
}
.tvFrameContainer{
  display: block;
  justify-content: space-between;
  padding: 5px 20px 10px;
  border-bottom: 1px solid darkgray;
}
.televisionLogo{
  text-align: center;
  padding-top: 10%;
}
.televisionFrame{
  width: 100%;
  padding: 5px 100px 10px;
}
.frameImage{
  height: 270px;
  width: 100%;
}
.tvGrid{
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 5px;
  width: 100%;
}
.tvCardTitle{
  margin: 5px 0px;
  font-weight: bold;
}
.tvCardimg{
  height: 150px;
  width: 100%;
  background-color: white;
  border-radius: 10px;
}
.tv-card-mobile{
  display: flex;
  flex-direction: column;
}
/* Shows Section  */

.showsContainer{
  padding: 10px 20px;
  border-bottom: 1px solid darkgray;
  display: none;
}
.showsContainer-mobile{
  display: block;
    padding: 10px 20px;
  border-bottom: 1px solid darkgray;
}

.showsSlider{
  width: 90%;
  margin: auto;
}
.sliderCards{
  overflow-x: scroll;
  display: flex;
  column-gap: 50px;
  margin: auto;
  color: black;
  padding: 20px 20px 0px;
}
.sliderCard{
  display: flex;
  flex-direction: column;
  width: 200px;
  height: 300px;
}
.sliderCard li{
  list-style: none;
  padding: 5px 0px;
}

.sliderCardImg{
  height: 200px;
  width: 200px;
  border-radius: 50%;
  background-color: white;
  margin-bottom: 5px;
}

.dtailsBanner{
  width: 100%;
  height: auto;
  align-items: start;
  display: flex;
  margin: 20px 0px;
}
.imageHolder{
  height: 200px;
  width: 100%;
}
.about-container{
    padding: 10px 30px;
}
.socialMed{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.med_icon{
  margin: 20px;
}
.mediaContact{
  display: flex;
  align-items: center;
  column-gap: 10px;
  justify-content: space-between;

}
.contact-container{
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 50px 10px;
}
.contact{
  width: 99%;
  margin: auto;
}

/* footer section */

.footerContainer{
  padding: 20px;
  background-color: black;
  color: white;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  justify-content: space-between;
  gap: 10px;
  border-top: 1px solid slategray;
  font-size: 15px;
}
.siteMap{
  display: none;
}
.socialMedia{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.copyRight{
  background-color: white;
  padding: 2px 28px;
  display: flex;
  justify-content: space-between;
  border-top: 1px solid slategray;
  font-size: 14px;
}




}





